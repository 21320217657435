
import React, { useState } from 'react';
import { Button, Typography, Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import {  useDispatch } from 'react-redux';
import { convertFloatDotSeperated } from "../helpers/common";
import { createDiscountsApi } from '../services/invoices';
import { LocalStorageService } from '../helpers/LocalStorage';
import {setFinancialInstitutionsData,setInvoicesData} from '../reducers/financeSlice'

function AmountReceived({ calculatedDiscount, total, invoices, setTabKey, setLoadingx }: any) {
   const { Text } = Typography;
   let navigate = useNavigate();
   const dispatch = useDispatch();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [applicationNumber, setApplicationNumber] = useState('');
   const platformCode = LocalStorageService.getPlatformCode();
   dispatch(setFinancialInstitutionsData(calculatedDiscount));
   dispatch(setInvoicesData(invoices));

   const handleClick = async () => {
      setLoadingx(true);
      const newData = {
         buyer: {
            id: invoices[0].buyerId,
            title: invoices[0].buyerTitle,
            taxId: invoices[0].buyerTaxNumber,
         },
         averageMaturity: calculatedDiscount[0].averageMaturity,
         invoices: invoices.map((item: any) => {
            return (
               {
                  id: item.id,
                  date: item.invoiceDate,
                  fileName: item.fileName,
                  number: item.invoiceNumber,
                  hashCode: item.hashCode,
                  total: item.invoiceTotal,
                  currency: item.currency,
                  invoiceType: item.invoiceType,
                  term: item.invoiceTerm,
                  isApproved: false,
                  createdDate: item.createdDate
               }
            )
         }),
         financialInstitutions: {
            id: calculatedDiscount[0].financialInstitutionId,
            name: calculatedDiscount[0].financialInstitutionName
         }
      };

      if (newData && Object.keys(newData).length > 0) {
         try {
            const response: any = await createDiscountsApi(newData);
            setApplicationNumber(response.applicationNumber);
            setIsModalOpen(true);
            setLoadingx(false)
            setTimeout(() => {
               setIsModalOpen(false);
               window.location.reload(); 
             }, 8000); 
         } catch (e: any) {
            console.log(e);
            setLoadingx(false)
            if (e.data.Status === 406 && e.data.Type.includes('NotEnoughAgriculturistLimitException')) {
               Modal.confirm({
                  title: 'Uyarı',
                  content: 'Çiftçi limitiniz yetersiz. Lütfen alıcınızla iletişime geçin.',
                  okText: 'Tamam',
                  cancelButtonProps: { style: { display: 'none' } }, 
                }); 
            }
            if (e.data.Status === 406 && e.data.Type.includes('NotEnoughBuyerLimitException')) {
               Modal.confirm({
                  title: 'Uyarı',
                  content: 'Alıcınızın limiti yetersiz. Lütfen alıcınızla irtibata geçin.',
                  okText: 'Tamam',
                  cancelButtonProps: { style: { display: 'none' } }, 
                });
            }
         }
      }
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      setTabKey('2')
   };

   const handleOffersClick = () => {
      navigate('/offers');
   }

   return (
      <>
      {
         platformCode === '2' ? (
         <div className='amount-received'>
            <div className='amount-received-txt' >
               <Text className='amount-received-t1'>Elinize Geçecek Tutar:</Text>
               <Text className='amount-received-t2'>{total !== null || total !== '' ? `${convertFloatDotSeperated(total)} TL` : ''}</Text>
            </div>
            <Button onClick={handleClick} className='amount-received-link'  disabled={invoices.length === 0} >Denizbank'a Hemen Başvur!</Button>
         </div>

         ): (
            <div className='amount-received'>
               <Button onClick={handleOffersClick} className='amount-received-link' disabled={invoices.length === 0} >Teklifleri Gör</Button>
            </div>
         )
      }

         <Modal
            closable={false}
            open={isModalOpen}
            footer={[
               <Button key="ok" type="primary" onClick={handleCancel}>
                  Kapat
               </Button>
            ]}
         >
            <p>{applicationNumber} numaralı başvurunuz başarıyla oluşturuldu. Başvurunuzun durumunu "Başvurularım" sekmesinden takip edebilirsiniz.</p>
         </Modal>
      </>
   );
}

export default AmountReceived;

