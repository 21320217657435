import React, {useEffect, useState} from "react";
import {  Button,Modal } from "antd";
import { useSelector } from 'react-redux';
import { ArrowLeftOutlined} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from '../helpers/LocalStorage';
import { createDiscountsApi } from '../services/invoices';
import { convertFloatDotSeperated } from "../helpers/common";
import denizbank from '../assets/bankLogo/Denizbank.png';
import akbank from '../assets/bankLogo/Akbank.png';
import denizFaktoring from '../assets/bankLogo/DenizFaktoringLogo.png';
import dyatirim from '../assets/bankLogo/Bankasi-D-Yatirim-Bankasi.png';
import sekerBank from '../assets/bankLogo/sekerbank-logo.png'

const Offers = () => {
  const navigate = useNavigate();
  const [applicationNumber, setApplicationNumber] = useState('');
  const [loadingx, setLoadingx] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([])

  const {financialInstitutions,invoices} = useSelector((state: any) => state.finance);

  const handleClickBack = () => {
    navigate('/invoices-application'); 
    LocalStorageService.setTabKey('1')
  };

  const handleClick = async (financialInstitutionId:any,financialInstitutionName:any) => {
      setLoadingx(true)
      const newData = {
         buyer: {
            id: invoices[0].buyerId,
            title: invoices[0].buyerTitle,
            taxId: invoices[0].buyerTaxNumber,
         },
         averageMaturity: financialInstitutions[0].averageMaturity,
         invoices: invoices.map((item: any) => {
            return (
               {
                  id: item.id,
                  date: item.invoiceDate,
                  fileName: item.fileName,
                  number: item.invoiceNumber,
                  hashCode: item.hashCode,
                  total: item.invoiceTotal,
                  currency: item.currency,
                  invoiceType: item.invoiceType,
                  term: item.invoiceTerm,
                  isApproved: false,
                  createdDate: item.createdDate
               }
            )
         }),
         financialInstitutions: {
            id:financialInstitutionId,
            name:financialInstitutionName
         }
      };

      if (newData && Object.keys(newData).length > 0) {
         try {
            const response: any = await createDiscountsApi(newData);
            setApplicationNumber(response.applicationNumber);
            setIsModalOpen(true);
            setLoadingx(false)
            setTimeout(() => {
               setIsModalOpen(false);
               window.location.reload(); 
             }, 8000); 
         } catch (e: any) {
          
            console.log(e);
            setLoadingx(false);
            if (e.data.Status === 406 && e.data.Type.includes('NotEnoughAgriculturistLimitException')) {
              Modal.confirm({
                title: 'Uyarı',
                content: 'Çiftçi limitiniz yetersiz.Lütfen alıcınızla iletişime geçin.',
                okText: 'Tamam',
                cancelButtonProps: { style: { display: 'none' } }, 
              });
            }
            if (e.data.Status === 406 && e.data.Type.includes('NotEnoughBuyerLimitException')) {
              Modal.confirm({
                title: 'Uyarı',
                content: 'Alıcınızın limiti yetersiz. Lütfen alıcınızla irtibata geçin.',
                okText: 'Tamam',
                cancelButtonProps: { style: { display: 'none' } }, 
              });
            }
            if (e.data.Status === 403 && e.data.Type.includes('InterestRatesExpiredException')) {
              Modal.confirm({
                title: 'Uyarı',
                content: ( <div className='common-err'>
                İşleminiz şu anda gerçekleştirilemiyor lütfen bizimle <a href="mailto:destek@depar.io">destek@depar.io</a> e-posta adresinden ya da <br/> <a href="tel:+905348123264">0534 812 32 64</a>  numaralı telefondan iletişime geçiniz.
                </div>),
                okText: 'Tamam',
                cancelButtonProps: { style: { display: 'none' } }, 
              });
           }
         }
      } 
   };

  const getLogo = (vkn:any, name:any) => {
    let content;
    if (vkn=== '0150015264') {
        content = <img src={akbank} alt="akbank" width="160px" /> 
      } 
      else if(vkn=== '2920084496'){
        content = <img src={denizbank} alt="akbank" width="160px" /> 
      }
      else if(vkn=== '2651554884'){
        content = <img src={dyatirim} alt="akbank" width="160px" /> 
      }
      else if(vkn=== '2910141668'){
        content = <img src={denizFaktoring} alt="akbank" width="160px" /> 
      } 
      else if(vkn=== '8010048575'){
        content = <img src={sekerBank} alt="akbank" width="160px" /> 
      }
      else{
        content = <h4 style={{paddingLeft:'10px'}}>{name}</h4> 
      }
      return content
  }

  const handleCancel = () => {
    setIsModalOpen(false);
    navigate('/invoices-application'); 
    LocalStorageService.setTabKey('2')
 };

 useEffect(() => {
  setData(financialInstitutions.slice().sort((a: any, b: any) => b.calculatedSumAmount - a.calculatedSumAmount));
}, [financialInstitutions]);
  
  return (
    <>
    <div className="routing-space" >
      <Button
        type="primary"
        icon={<ArrowLeftOutlined />}
        onClick={handleClickBack}
      >
          Faturalarıma Geri Dön
      </Button>
    </div>

   
    <h3 className='noti-row'>Teklifler:</h3>
     <div className="offers-list">
      {
        financialInstitutions.length < 1 ?
        <p>Bir hata oluştu Faturalarıma geri dönünüz.</p>
        :
        data && data.map((item:any) => {
          return(
            <div className="table-row" key={item.id}>
              <div className='table-div-first'>
                <div className="table-col">
                  {
                    item.financialInstitutionName  ? 
                    getLogo(item.financialInstitutionTaxId, item.financialInstitutionName)
                    :
                    item.financialInstitutionName
                  }
                
                </div>
                {/* <div className="table-col mobilt">
                    <span className="title">Mevcut Limit:</span>
                    <span>100 TL</span>
                </div>
                <div className="table-col mobilt">
                    <span className="title">Mevcut Risk:</span>
                    <span>100 TL</span>
                </div> */}
                <div className='table-div'>
                  <div className="table-col mobilt">
                      <span className="title">Teklif Tutarı:</span>
                      <span>{convertFloatDotSeperated(item.calculatedSumAmount)} TL</span>
                  </div>
                  {/* <div className="table-col mobilt">
                      <span className="title">Kalacak Limit:</span>
                      <span>-</span>
                  </div> */}
                  <div className="table-col">
                      <Button  className="apply-box btn-color" 
                        onClick={() => handleClick(item.financialInstitutionId, item.financialInstitutionName)} 
                        loading={loadingx}
                        >Hemen Başvur</Button>
                  </div>
                </div>
              </div>
          </div>
          )
        })
      }
     </div>
     <Modal
        closable={false}
        open={isModalOpen}
        footer={[
          <Button key="ok" type="primary" onClick={handleCancel}>
            Kapat
          </Button>
          ]}
         >
            <p>{applicationNumber} numaralı başvurunuz başarıyla oluşturuldu. Başvurunuzun durumunu "Başvurularım" sekmesinden takip edebilirsiniz.</p>
      </Modal>
      
    </>
  );
};

export default Offers;
